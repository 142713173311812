import { Suspense, lazy } from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

const Raiz = lazy(() => import('../pages/Usuario/Raiz'));
const PaginaError = lazy(() => import('../pages/Usuario/Error'));
const PaginaErrorAdmin = lazy(() => import('../pages/Admin/Error'));

const PaginaVivo = lazy(() => import('../pages/Usuario/Vivo'));

const PaginaTorneos = lazy(() => import('../pages/Usuario/Torneos'));
const PaginaTorneo = lazy(() => import('../pages/Usuario/Torneo'));

const PaginaFechas = lazy(() => import('../pages/Usuario/Fechas'));
const PaginaFecha = lazy(() => import('../pages/Usuario/Fecha'));

const PaginaH2H = lazy(() => import('../pages/Usuario/H2H'));
const PaginaPerfil = lazy(() => import('../pages/Usuario/Perfil'));

const PaginaNoEncontrada = lazy(() => import('../pages/Usuario/NotFound'));

const RaizAdmin = lazy(() => import('../pages/Admin/RaizAdmin'));
const PaginaAdmin = lazy(() => import('../pages/Admin/Admin'));
const PaginaLogin = lazy(() => import('../pages/Admin/Login'));

const PaginaTorneosAdmin = lazy(() => import('../pages/Admin/Torneos/Torneos'));
const PaginaEditarTorneo = lazy(() =>
  import('../pages/Admin/Torneos/EditarTorneo')
);
const PaginaNuevoTorneo = lazy(() =>
  import('../pages/Admin/Torneos/NuevoTorneo')
);
const PaginaInfoTorneo = lazy(() =>
  import('../pages/Admin/Torneos/InfoTorneo')
);

const PaginaEditarCategoriaTorneo = lazy(() =>
  import('../pages/Admin/CategoriasTorneo/EditarCategoriaTorneo')
);
const PaginaNuevaCategoriaTorneo = lazy(() =>
  import('../pages/Admin/CategoriasTorneo/NuevaCategoriaTorneo')
);
const PaginaInfoCategoriaTorneo = lazy(() =>
  import('../pages/Admin/CategoriasTorneo/InfoCategoriaTorneo')
);

const PaginaEditarCategoriaFecha = lazy(() =>
  import('../pages/Admin/CategoriasFecha/EditarCategoriaFecha')
);
const PaginaNuevaCategoriaFecha = lazy(() =>
  import('../pages/Admin/CategoriasFecha/NuevaCategoriaFecha')
);
const PaginaInfoCategoriaFecha = lazy(() =>
  import('../pages/Admin/CategoriasFecha/InfoCategoriaFecha')
);

const PaginaFechasAdmin = lazy(() => import('../pages/Admin/Fechas/Fechas'));
const PaginaEditarFecha = lazy(() =>
  import('../pages/Admin/Fechas/EditarFecha')
);
const PaginaNuevaFecha = lazy(() => import('../pages/Admin/Fechas/NuevaFecha'));
const PaginaInfoFecha = lazy(() => import('../pages/Admin/Fechas/InfoFecha'));

const PaginaEditarFase = lazy(() => import('../pages/Admin/Fases/EditarFase'));
const PaginaNuevaFase = lazy(() => import('../pages/Admin/Fases/NuevaFase'));
const PaginaInfoFase = lazy(() => import('../pages/Admin/Fases/InfoFase'));

const PaginaEditarGrupo = lazy(() =>
  import('../pages/Admin/Grupos/EditarGrupo')
);
const PaginaNuevoGrupo = lazy(() => import('../pages/Admin/Grupos/NuevoGrupo'));

const PaginaJugadoresAdmin = lazy(() =>
  import('../pages/Admin/Jugadores/Jugadores')
);
const PaginaEditarJugador = lazy(() =>
  import('../pages/Admin/Jugadores/EditarJugador')
);
const PaginaNuevoJugador = lazy(() =>
  import('../pages/Admin/Jugadores/NuevoJugador')
);

const PaginaEquiposAdmin = lazy(() => import('../pages/Admin/Equipos/Equipos'));
const PaginaEditarEquipo = lazy(() =>
  import('../pages/Admin/Equipos/EditarEquipo')
);
const PaginaNuevoEquipo = lazy(() =>
  import('../pages/Admin/Equipos/NuevoEquipo')
);

const PaginaClubesAdmin = lazy(() => import('../pages/Admin/Clubes/Clubes'));
const PaginaEditarClub = lazy(() => import('../pages/Admin/Clubes/EditarClub'));
const PaginaNuevoClub = lazy(() => import('../pages/Admin/Clubes/NuevoClub'));

const PaginaCiudadesAdmin = lazy(() =>
  import('../pages/Admin/Ciudades/Ciudades')
);
const PaginaEditarCiudad = lazy(() =>
  import('../pages/Admin/Ciudades/EditarCiudad')
);
const PaginaNuevaCiudad = lazy(() =>
  import('../pages/Admin/Ciudades/NuevaCiudad')
);

const PaginaPuntajesElimSimpleAdmin = lazy(() =>
  import('../pages/Admin/PuntajesElimSimple/PuntajesElimSimple')
);
const PaginaEditarPuntajeElimSimple = lazy(() =>
  import('../pages/Admin/PuntajesElimSimple/EditarPuntajeElimSimple')
);
const PaginaNuevoPuntajeElimSimple = lazy(() =>
  import('../pages/Admin/PuntajesElimSimple/NuevoPuntajeElimSimple')
);

const PaginaPuntajesElimDobleAdmin = lazy(() =>
  import('../pages/Admin/PuntajesElimDoble/PuntajesElimDoble')
);
const PaginaEditarPuntajeElimDoble = lazy(() =>
  import('../pages/Admin/PuntajesElimDoble/EditarPuntajeElimDoble')
);
const PaginaNuevoPuntajeElimDoble = lazy(() =>
  import('../pages/Admin/PuntajesElimDoble/NuevoPuntajeElimDoble')
);

const PaginaStreamsAdmin = lazy(() => import('../pages/Admin/Streams/Streams'));
const PaginaEditarStream = lazy(() =>
  import('../pages/Admin/Streams/EditarStream')
);
const PaginaNuevoStream = lazy(() =>
  import('../pages/Admin/Streams/NuevoStream')
);

const PaginaEditarVivo = lazy(() => import('../pages/Admin/Vivo/EditarVivo'));

const PaginaVisitas = lazy(() => import('../pages/Admin/Visitas/Visitas'));

const PaginaH2HAdmin = lazy(() => import('../pages/Admin/H2H/H2H'));

const PaginaEncuentro = lazy(() =>
  import('../pages/Admin/Encuentros/Encuentro')
);

export const router = createBrowserRouter([
  {
    path: '/login',
    element: (
      <Suspense fallback={<p className="descripcion">Cargando...</p>}>
        <PaginaLogin />
      </Suspense>
    ),
  },

  {
    path: '/admin',
    element: (
      <Suspense fallback={<p className="descripcion">Cargando...</p>}>
        <RaizAdmin />
      </Suspense>
    ),
    loader: (meta) =>
      import('../pages/Admin/Vivo/EditarVivo').then((module) =>
        module.loader(meta)
      ),
    errorElement: <PaginaErrorAdmin />,
    children: [
      {
        index: true,
        element: <Navigate to="/admin/inicio" />,
      },
      {
        path: 'inicio',
        element: (
          <Suspense fallback={<p className="descripcion">Cargando...</p>}>
            <PaginaAdmin />
          </Suspense>
        ),
      },

      {
        path: 'torneos',
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaTorneosAdmin />
              </Suspense>
            ),
            loader: (meta) =>
              import('../pages/Admin/Torneos/Torneos').then((module) =>
                module.loader(meta)
              ),
          },
          {
            path: 'nuevo',
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaNuevoTorneo />
              </Suspense>
            ),
            loader: (meta) =>
              import('../pages/Admin/Torneos/EditarTorneo').then((module) =>
                module.loader(meta)
              ),
            action: (meta) =>
              import('../components/Admin/Torneos/FormularioTorneo').then(
                (module) => module.action(meta)
              ),
          },
          {
            path: ':idTorneo',
            children: [
              {
                index: true,
                element: (
                  <Suspense
                    fallback={<p className="descripcion">Cargando...</p>}
                  >
                    <PaginaInfoTorneo />
                  </Suspense>
                ),
                loader: (meta) =>
                  import('../pages/Admin/Torneos/InfoTorneo').then((module) =>
                    module.loader(meta)
                  ),
              },
              {
                path: 'categorias',
                children: [
                  {
                    path: 'nuevo',
                    element: (
                      <Suspense
                        fallback={<p className="descripcion">Cargando...</p>}
                      >
                        <PaginaNuevaCategoriaTorneo />
                      </Suspense>
                    ),
                    loader: (meta) =>
                      import(
                        '../pages/Admin/CategoriasTorneo/EditarCategoriaTorneo'
                      ).then((module) => module.loader(meta)),
                    action: (meta) =>
                      import(
                        '../components/Admin/CategoriasTorneo/FormularioCategoriaTorneo'
                      ).then((module) => module.action(meta)),
                  },
                  {
                    path: ':idCategoriaTorneo',
                    children: [
                      {
                        index: true,
                        element: (
                          <Suspense
                            fallback={
                              <p className="descripcion">Cargando...</p>
                            }
                          >
                            <PaginaInfoCategoriaTorneo />
                          </Suspense>
                        ),
                        loader: (meta) =>
                          import(
                            '../pages/Admin/CategoriasTorneo/InfoCategoriaTorneo'
                          ).then((module) => module.loader(meta)),
                      },
                      {
                        path: 'editar',
                        element: (
                          <Suspense
                            fallback={
                              <p className="descripcion">Cargando...</p>
                            }
                          >
                            <PaginaEditarCategoriaTorneo />
                          </Suspense>
                        ),
                        loader: (meta) =>
                          import(
                            '../pages/Admin/CategoriasTorneo/EditarCategoriaTorneo'
                          ).then((module) => module.loader(meta)),
                        action: (meta) =>
                          import(
                            '../components/Admin/CategoriasTorneo/FormularioCategoriaTorneo'
                          ).then((module) => module.action(meta)),
                      },
                    ],
                  },
                ],
              },
              {
                path: 'editar',
                children: [
                  {
                    index: true,
                    element: (
                      <Suspense
                        fallback={<p className="descripcion">Cargando...</p>}
                      >
                        <PaginaEditarTorneo />
                      </Suspense>
                    ),
                    loader: (meta) =>
                      import('../pages/Admin/Torneos/EditarTorneo').then(
                        (module) => module.loader(meta)
                      ),
                    action: (meta) =>
                      import(
                        '../components/Admin/Torneos/FormularioTorneo'
                      ).then((module) => module.action(meta)),
                  },
                ],
              },
            ],
          },
          {
            path: ':idTorneo/editar',
          },
        ],
      },

      {
        path: 'fechas',
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaFechasAdmin />
              </Suspense>
            ),
            loader: (meta) =>
              import('../pages/Admin/Fechas/Fechas').then((module) =>
                module.loader(meta)
              ),
          },
          {
            path: 'nuevo',
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaNuevaFecha />
              </Suspense>
            ),
            loader: (meta) =>
              import('../pages/Admin/Fechas/EditarFecha').then((module) =>
                module.loader(meta)
              ),
            action: (meta) =>
              import('../components/Admin/Fechas/FormularioFecha').then(
                (module) => module.action(meta)
              ),
          },
          {
            path: ':idFecha',
            children: [
              {
                index: true,
                element: (
                  <Suspense
                    fallback={<p className="descripcion">Cargando...</p>}
                  >
                    <PaginaInfoFecha />
                  </Suspense>
                ),
                loader: (meta) =>
                  import('../pages/Admin/Fechas/InfoFecha').then((module) =>
                    module.loader(meta)
                  ),
              },
              {
                path: 'editar',
                children: [
                  {
                    index: true,
                    element: (
                      <Suspense
                        fallback={<p className="descripcion">Cargando...</p>}
                      >
                        <PaginaEditarFecha />
                      </Suspense>
                    ),
                    loader: (meta) =>
                      import('../pages/Admin/Fechas/EditarFecha').then(
                        (module) => module.loader(meta)
                      ),
                    action: (meta) =>
                      import('../components/Admin/Fechas/FormularioFecha').then(
                        (module) => module.action(meta)
                      ),
                  },
                  {
                    path: 'streams',
                    children: [
                      {
                        path: 'nuevo',
                        element: (
                          <Suspense
                            fallback={
                              <p className="descripcion">Cargando...</p>
                            }
                          >
                            <PaginaNuevoStream />
                          </Suspense>
                        ),
                        loader: (meta) =>
                          import('../pages/Admin/Streams/EditarStream').then(
                            (module) => module.loader(meta)
                          ),
                        action: (meta) =>
                          import(
                            '../components/Admin/Streams/FormularioStream'
                          ).then((module) => module.action(meta)),
                      },
                      {
                        path: ':idStream/editar',
                        element: (
                          <Suspense
                            fallback={
                              <p className="descripcion">Cargando...</p>
                            }
                          >
                            <PaginaEditarStream />
                          </Suspense>
                        ),
                        loader: (meta) =>
                          import('../pages/Admin/Streams/EditarStream').then(
                            (module) => module.loader(meta)
                          ),
                        action: (meta) =>
                          import(
                            '../components/Admin/Streams/FormularioStream'
                          ).then((module) => module.action(meta)),
                      },
                    ],
                  },
                  {
                    path: 'categorias',
                    children: [
                      {
                        path: 'nuevo',
                        element: (
                          <Suspense
                            fallback={
                              <p className="descripcion">Cargando...</p>
                            }
                          >
                            <PaginaNuevaCategoriaFecha />
                          </Suspense>
                        ),
                        loader: (meta) =>
                          import(
                            '../pages/Admin/CategoriasFecha/EditarCategoriaFecha'
                          ).then((module) => module.loader(meta)),
                        action: (meta) =>
                          import(
                            '../components/Admin/CategoriasFecha/FormularioCategoriaFecha'
                          ).then((module) => module.action(meta)),
                      },
                      {
                        path: ':idCategoriaFecha',
                        children: [
                          {
                            index: true,
                            element: (
                              <Suspense
                                fallback={
                                  <p className="descripcion">Cargando...</p>
                                }
                              >
                                <PaginaInfoCategoriaFecha />
                              </Suspense>
                            ),
                            loader: (meta) =>
                              import(
                                '../pages/Admin/CategoriasFecha/InfoCategoriaFecha'
                              ).then((module) => module.loader(meta)),
                          },
                          {
                            path: 'editar',
                            element: (
                              <Suspense
                                fallback={
                                  <p className="descripcion">Cargando...</p>
                                }
                              >
                                <PaginaEditarCategoriaFecha />
                              </Suspense>
                            ),
                            loader: (meta) =>
                              import(
                                '../pages/Admin/CategoriasFecha/EditarCategoriaFecha'
                              ).then((module) => module.loader(meta)),
                            action: (meta) =>
                              import(
                                '../components/Admin/CategoriasFecha/FormularioCategoriaFecha'
                              ).then((module) => module.action(meta)),
                          },
                          {
                            path: 'fases',
                            children: [
                              {
                                path: 'nuevo',
                                element: (
                                  <Suspense
                                    fallback={
                                      <p className="descripcion">Cargando...</p>
                                    }
                                  >
                                    <PaginaNuevaFase />
                                  </Suspense>
                                ),
                                loader: (meta) =>
                                  import(
                                    '../pages/Admin/Fases/EditarFase'
                                  ).then((module) => module.loader(meta)),
                                action: (meta) =>
                                  import(
                                    '../components/Admin/Fases/FormularioFase'
                                  ).then((module) => module.action(meta)),
                              },
                              {
                                path: ':idFase',
                                children: [
                                  {
                                    index: true,
                                    element: (
                                      <Suspense
                                        fallback={
                                          <p className="descripcion">
                                            Cargando...
                                          </p>
                                        }
                                      >
                                        <PaginaInfoFase />
                                      </Suspense>
                                    ),
                                    loader: (meta) =>
                                      import(
                                        '../pages/Admin/Fases/InfoFase'
                                      ).then((module) => module.loader(meta)),
                                  },
                                  {
                                    path: 'editar',
                                    element: (
                                      <Suspense
                                        fallback={
                                          <p className="descripcion">
                                            Cargando...
                                          </p>
                                        }
                                      >
                                        <PaginaEditarFase />
                                      </Suspense>
                                    ),
                                    loader: (meta) =>
                                      import(
                                        '../pages/Admin/Fases/EditarFase'
                                      ).then((module) => module.loader(meta)),
                                    action: (meta) =>
                                      import(
                                        '../components/Admin/Fases/FormularioFase'
                                      ).then((module) => module.action(meta)),
                                  },
                                  {
                                    path: 'grupo/:idGrupo',
                                    children: [
                                      {
                                        index: true,
                                        element: (
                                          <Suspense
                                            fallback={
                                              <p className="descripcion">
                                                Cargando...
                                              </p>
                                            }
                                          >
                                            <PaginaInfoFase />
                                          </Suspense>
                                        ),
                                        loader: (meta) =>
                                          import(
                                            '../pages/Admin/Fases/InfoFase'
                                          ).then((module) =>
                                            module.loader(meta)
                                          ),
                                      },
                                      {
                                        path: 'editar',
                                        element: (
                                          <Suspense
                                            fallback={
                                              <p className="descripcion">
                                                Cargando...
                                              </p>
                                            }
                                          >
                                            <PaginaEditarGrupo />
                                          </Suspense>
                                        ),
                                        loader: (meta) =>
                                          import(
                                            '../pages/Admin/Grupos/EditarGrupo'
                                          ).then((module) =>
                                            module.loader(meta)
                                          ),
                                        action: (meta) =>
                                          import(
                                            '../components/Admin/Grupos/FormularioGrupo'
                                          ).then((module) =>
                                            module.action(meta)
                                          ),
                                      },
                                      {
                                        path: 'nuevo',
                                        element: (
                                          <Suspense
                                            fallback={
                                              <p className="descripcion">
                                                Cargando...
                                              </p>
                                            }
                                          >
                                            <PaginaNuevoGrupo />
                                          </Suspense>
                                        ),
                                        loader: (meta) =>
                                          import(
                                            '../pages/Admin/Grupos/EditarGrupo'
                                          ).then((module) =>
                                            module.loader(meta)
                                          ),
                                        action: (meta) =>
                                          import(
                                            '../components/Admin/Grupos/FormularioGrupo'
                                          ).then((module) =>
                                            module.action(meta)
                                          ),
                                      },
                                    ],
                                  },
                                  {
                                    path: 'eliminatoria/:idEliminatoria',
                                    children: [
                                      {
                                        index: true,
                                        element: (
                                          <Suspense
                                            fallback={
                                              <p className="descripcion">
                                                Cargando...
                                              </p>
                                            }
                                          >
                                            <PaginaInfoFase />
                                          </Suspense>
                                        ),
                                        loader: (meta) =>
                                          import(
                                            '../pages/Admin/Fases/InfoFase'
                                          ).then((module) =>
                                            module.loader(meta)
                                          ),
                                      },
                                    ],
                                  },
                                  {
                                    path: 'preliminar/:idPreliminar',
                                    children: [
                                      {
                                        index: true,
                                        element: (
                                          <Suspense
                                            fallback={
                                              <p className="descripcion">
                                                Cargando...
                                              </p>
                                            }
                                          >
                                            <PaginaInfoFase />
                                          </Suspense>
                                        ),
                                        loader: (meta) =>
                                          import(
                                            '../pages/Admin/Fases/InfoFase'
                                          ).then((module) =>
                                            module.loader(meta)
                                          ),
                                      },
                                    ],
                                  },
                                  {
                                    path: 'encuentro/:idEncuentro',
                                    children: [
                                      {
                                        index: true,
                                        element: (
                                          <Suspense
                                            fallback={
                                              <p className="descripcion">
                                                Cargando...
                                              </p>
                                            }
                                          >
                                            <PaginaEncuentro />
                                          </Suspense>
                                        ),
                                        loader: (meta) =>
                                          import(
                                            '../pages/Admin/Encuentros/Encuentro'
                                          ).then((module) =>
                                            module.loader(meta)
                                          ),
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        path: 'jugadores',
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaJugadoresAdmin />
              </Suspense>
            ),
            loader: (meta) =>
              import('../pages/Admin/Jugadores/Jugadores').then((module) =>
                module.loader(meta)
              ),
          },
          {
            path: 'nuevo',
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaNuevoJugador />
              </Suspense>
            ),
            loader: (meta) =>
              import('../pages/Admin/Jugadores/EditarJugador').then((module) =>
                module.loader(meta)
              ),
            action: (meta) =>
              import('../components/Admin/Jugadores/FormularioJugador').then(
                (module) => module.action(meta)
              ),
          },
          {
            path: ':idJugador/editar',
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaEditarJugador />
              </Suspense>
            ),
            loader: (meta) =>
              import('../pages/Admin/Jugadores/EditarJugador').then((module) =>
                module.loader(meta)
              ),
            action: (meta) =>
              import('../components/Admin/Jugadores/FormularioJugador').then(
                (module) => module.action(meta)
              ),
          },
        ],
      },

      {
        path: 'equipos',
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaEquiposAdmin />
              </Suspense>
            ),
            loader: (meta) =>
              import('../pages/Admin/Equipos/Equipos').then((module) =>
                module.loader(meta)
              ),
          },
          {
            path: 'nuevo',
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaNuevoEquipo />
              </Suspense>
            ),
            loader: (meta) =>
              import('../pages/Admin/Equipos/EditarEquipo').then((module) =>
                module.loader(meta)
              ),
            action: (meta) =>
              import('../components/Admin/Equipos/FormularioEquipo').then(
                (module) => module.action(meta)
              ),
          },
          {
            path: ':idEquipo/editar',
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaEditarEquipo />
              </Suspense>
            ),
            loader: (meta) =>
              import('../pages/Admin/Equipos/EditarEquipo').then((module) =>
                module.loader(meta)
              ),
            action: (meta) =>
              import('../components/Admin/Equipos/FormularioEquipo').then(
                (module) => module.action(meta)
              ),
          },
        ],
      },

      {
        path: 'clubes',
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaClubesAdmin />
              </Suspense>
            ),
            loader: (meta) =>
              import('../pages/Admin/Clubes/Clubes').then((module) =>
                module.loader(meta)
              ),
          },
          {
            path: 'nuevo',
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaNuevoClub />
              </Suspense>
            ),
            loader: (meta) =>
              import('../pages/Admin/Clubes/EditarClub').then((module) =>
                module.loader(meta)
              ),
            action: (meta) =>
              import('../components/Admin/Clubes/FormularioClub').then(
                (module) => module.action(meta)
              ),
          },
          {
            path: ':idClub/editar',
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaEditarClub />
              </Suspense>
            ),
            loader: (meta) =>
              import('../pages/Admin/Clubes/EditarClub').then((module) =>
                module.loader(meta)
              ),
            action: (meta) =>
              import('../components/Admin/Clubes/FormularioClub').then(
                (module) => module.action(meta)
              ),
          },
        ],
      },

      {
        path: 'ciudades',
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaCiudadesAdmin />
              </Suspense>
            ),
            loader: (meta) =>
              import('../pages/Admin/Ciudades/Ciudades').then((module) =>
                module.loader(meta)
              ),
          },
          {
            path: 'nuevo',
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaNuevaCiudad />
              </Suspense>
            ),
            loader: (meta) =>
              import('../pages/Admin/Ciudades/EditarCiudad').then((module) =>
                module.loader(meta)
              ),
            action: (meta) =>
              import('../components/Admin/Ciudades/FormularioCiudad').then(
                (module) => module.action(meta)
              ),
          },
          {
            path: ':idCiudad/editar',
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaEditarCiudad />
              </Suspense>
            ),
            loader: (meta) =>
              import('../pages/Admin/Ciudades/EditarCiudad').then((module) =>
                module.loader(meta)
              ),
            action: (meta) =>
              import('../components/Admin/Ciudades/FormularioCiudad').then(
                (module) => module.action(meta)
              ),
          },
        ],
      },

      {
        path: 'puntajes-elim-simple',
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaPuntajesElimSimpleAdmin />
              </Suspense>
            ),
            loader: (meta) =>
              import(
                '../pages/Admin/PuntajesElimSimple/PuntajesElimSimple'
              ).then((module) => module.loader(meta)),
          },
          {
            path: 'nuevo',
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaNuevoPuntajeElimSimple />
              </Suspense>
            ),
            loader: (meta) =>
              import(
                '../pages/Admin/PuntajesElimSimple/EditarPuntajeElimSimple'
              ).then((module) => module.loader(meta)),
            action: (meta) =>
              import(
                '../components/Admin/PuntajesElimSimple/FormularioPuntajeElimSimple'
              ).then((module) => module.action(meta)),
          },
          {
            path: ':idPuntajeElimSimple/editar',
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaEditarPuntajeElimSimple />
              </Suspense>
            ),
            loader: (meta) =>
              import(
                '../pages/Admin/PuntajesElimSimple/EditarPuntajeElimSimple'
              ).then((module) => module.loader(meta)),
            action: (meta) =>
              import(
                '../components/Admin/PuntajesElimSimple/FormularioPuntajeElimSimple'
              ).then((module) => module.action(meta)),
          },
        ],
      },

      {
        path: 'puntajes-elim-doble',
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaPuntajesElimDobleAdmin />
              </Suspense>
            ),
            loader: (meta) =>
              import('../pages/Admin/PuntajesElimDoble/PuntajesElimDoble').then(
                (module) => module.loader(meta)
              ),
          },
          {
            path: 'nuevo',
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaNuevoPuntajeElimDoble />
              </Suspense>
            ),
            loader: (meta) =>
              import(
                '../pages/Admin/PuntajesElimDoble/EditarPuntajeElimDoble'
              ).then((module) => module.loader(meta)),
            action: (meta) =>
              import(
                '../components/Admin/PuntajesElimDoble/FormularioPuntajeElimDoble'
              ).then((module) => module.action(meta)),
          },
          {
            path: ':idPuntajeElimDoble/editar',
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaEditarPuntajeElimDoble />
              </Suspense>
            ),
            loader: (meta) =>
              import(
                '../pages/Admin/PuntajesElimDoble/EditarPuntajeElimDoble'
              ).then((module) => module.loader(meta)),
            action: (meta) =>
              import(
                '../components/Admin/PuntajesElimDoble/FormularioPuntajeElimDoble'
              ).then((module) => module.action(meta)),
          },
        ],
      },

      {
        path: 'streams',
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaStreamsAdmin />
              </Suspense>
            ),
            loader: (meta) =>
              import('../pages/Admin/Streams/Streams').then((module) =>
                module.loader(meta)
              ),
          },
          {
            path: 'nuevo',
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaNuevoStream />
              </Suspense>
            ),
            loader: (meta) =>
              import('../pages/Admin/Vivo/EditarVivo').then((module) =>
                module.loader(meta)
              ),
            action: (meta) =>
              import('../components/Admin/Streams/FormularioStream').then(
                (module) => module.action(meta)
              ),
          },
          {
            path: ':idStream/editar',
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaEditarStream />
              </Suspense>
            ),
            loader: (meta) =>
              import('../pages/Admin/Streams/EditarStream').then((module) =>
                module.loader(meta)
              ),
            action: (meta) =>
              import('../components/Admin/Streams/FormularioStream').then(
                (module) => module.action(meta)
              ),
          },
        ],
      },
      {
        path: 'vivo',
        element: (
          <Suspense
            fallback={<p className="descripcion">Página no encontrada</p>}
          >
            <PaginaEditarVivo />
          </Suspense>
        ),
        loader: (meta) =>
          import('../pages/Admin/Vivo/EditarVivo').then((module) =>
            module.loader(meta)
          ),
        action: (meta) =>
          import('../components/Admin/Streams/FormularioStream').then(
            (module) => module.action(meta)
          ),
      },
      {
        path: 'visitas',
        element: (
          <Suspense fallback={<p className="descripcion">Cargando...</p>}>
            <PaginaVisitas />
          </Suspense>
        ),
        loader: (meta) =>
          import('../pages/Admin/Visitas/Visitas').then((module) =>
            module.loader(meta)
          ),
      },
      {
        path: 'h2h',
        element: (
          <Suspense fallback={<p className="descripcion">Cargando...</p>}>
            <PaginaH2HAdmin />
          </Suspense>
        ),
        loader: (meta) =>
          import('../pages/Admin/H2H/H2H').then((module) =>
            module.loader(meta)
          ),
      },
      {
        path: '*',
        element: (
          <Suspense
            fallback={<p className="descripcion">Página no encontrada</p>}
          >
            <PaginaNoEncontrada />
          </Suspense>
        ),
      },
    ],
  },

  {
    path: '/',
    element: (
      <Suspense fallback={<p className="descripcion">Cargando...</p>}>
        <Raiz />
      </Suspense>
    ),
    loader: (meta) =>
      import('../pages/Usuario/Raiz').then((module) => module.loader(meta)),
    errorElement: <PaginaError />,
    children: [
      {
        index: true,
        element: <Navigate to="/fechas" />,
      },

      {
        path: 'vivo',
        element: (
          <Suspense fallback={<p className="descripcion">Cargando...</p>}>
            <PaginaVivo />
          </Suspense>
        ),
        loader: (meta) =>
          import('../pages/Usuario/Raiz').then((module) => module.loader(meta)),
      },

      {
        path: 'torneos',
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaTorneos />
              </Suspense>
            ),
            loader: (meta) =>
              import('../pages/Usuario/Torneos').then((module) =>
                module.loader(meta)
              ),
          },
          {
            path: ':slugTorneo',
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaTorneo />
              </Suspense>
            ),
            loader: (meta) =>
              import('../pages/Usuario/Torneo').then((module) =>
                module.loader(meta)
              ),
          },
        ],
      },

      {
        path: 'fechas',
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaFechas />
              </Suspense>
            ),
            loader: (meta) =>
              import('../pages/Usuario/Fechas').then((module) =>
                module.loader(meta)
              ),
          },
          {
            path: ':slugFecha',
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaFecha />
              </Suspense>
            ),
            loader: (meta) =>
              import('../pages/Usuario/Fecha').then((module) =>
                module.loader(meta)
              ),
          },
        ],
      },

      {
        path: 'h2h',
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaH2H />
              </Suspense>
            ),
            loader: (meta) =>
              import('../pages/Usuario/H2H').then((module) =>
                module.loader(meta)
              ),
          },
        ],
      },

      {
        path: 'perfil',
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<p className="descripcion">Cargando...</p>}>
                <PaginaPerfil />
              </Suspense>
            ),
            loader: (meta) =>
              import('../pages/Usuario/Perfil').then((module) =>
                module.loader(meta)
              ),
          },
        ],
      },

      {
        path: '*',
        element: (
          <Suspense
            fallback={<p className="descripcion">Página no encontrada</p>}
          >
            <PaginaNoEncontrada />
          </Suspense>
        ),
      },
    ],
  },
]);
